import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const FooterWrapper = styled.div`
  background-color: #202020;
`;

export const Links = styled.div`
 display: flex;
 justify-content: center;
 `;

export const Link = styled.a`
  font-family: Sansita;
  font-size: 36px;
  color: #F8EDDD;
  text-decoration: underline;
  margin-left: 30px;
  cursor: pointer;
  &:hover{
    color: #A99985;
  }
`;

export const useStyles = makeStyles({
  title: {
    fontSize: '30px',
    color: 'white',
    fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif; ',
    color: 'white',
  },
  footerBio: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display:'initial'
    },
  },
  footerSection: {
    textAlign: 'center',
    display: 'block',
    padding: '20px 30px 70px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '50px',
    },
  },
  '@keyframes enlarge': {
    '100%': {
      transform: 'scale(1.2,1.2)',
    },
  },
  licenseText: {
    color: 'white',
    fontFamily: 'Sansita',
    fontSize: '36px',

  },
  linkedin: {
    marginLeft: '8px',
    color: 'white',
    '&:hover': {
      color: '#0e76a8',
    },
  },
  github: {
    marginLeft: '8px',
    color: 'white',
    '&:hover': {
      color: 'purple',
    },
  },
});
